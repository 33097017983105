import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { HerokuURL } from '../../constants';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import Loader from '../helper/loader';
import { editStyling, inputStyle, floatcontainer } from '../helper/sharedStyling';


const UserTravelInfoLookUp = (props) => {

  const [sangatValue, setSangatValue] = useState({
    user_firstName: "",
    user_lastName: "",
    user_phoneNumber: "",
  });

  const [show, setShow] = useState(true);
  const [userInput, setUserInput] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Please enter the following to look up your travel info:");
  const [showValidationMessages, setShowValidationMessages] = useState(false);




  const handleClose = () => {
    setShow(false);
    window.location.reload();
  }

  const setSangatFirstName = (e) => {
    e.preventDefault();
    const removeWhiteSpace = e.target.value.trim()
    setSangatValue({ ...sangatValue, user_firstName: removeWhiteSpace.toUpperCase() });
  }

  const setSangatLastName = (e) => {
    e.preventDefault();
    const removeWhiteSpace = e.target.value.trim()
    setSangatValue({ ...sangatValue, user_lastName: removeWhiteSpace.toUpperCase()});
  }

  const setSangatPhoneNUmber= (e) => {
    e.preventDefault();
    const phoneNumber = e.target.value.replace(/\s/g, "");
    if(sangatValue.user_phoneNumber.length==10){
      return;
    }
    if(isNaN(phoneNumber)){
      setShowValidationMessages(true);
    }
    setSangatValue({ ...sangatValue, user_phoneNumber: phoneNumber});
        
  }


  const [data, setState] = useState([])

  const addNewHost = async () => {

    setLoading(true);

    fetch(`${HerokuURL}api/findTravelInfo?user_firstName=${sangatValue.user_firstName}&user_lastName=${sangatValue.user_lastName}&user_phoneNumber=${sangatValue.user_phoneNumber}`, {
      method: 'GET',
      headers: {
        "x-access-token": localStorage.getItem('accessToken'),
        "Content-Type": 'application/json',
      },
    }).then((res) => res.json()).then((jsonRes) => {
      setState(jsonRes);
      setLoading(false);
      setUserInput(false);
      setMessage(`Record found for ${jsonRes.user_firstName}`)
    })

  }

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> {message} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#f2f2f2' }}>
          {loading && <Loader />}
          {data != null && data.length != 0 ?
            <div style={{ marginBottom: '20px', fontWeight: 'bold' }}>Your record found</div>
            :
            data == null &&
            <div>No Record found. Please try again later with valid info</div>
          }
          {userInput ?
            <div style={{ ...editStyling }}>
              First Name *
            <input style={{ ...inputStyle, borderColor: sangatValue.user_firstName === "" ? 'red' : "" }} value={sangatValue.user_firstName} onChange={e => setSangatFirstName(e)} />
            Last Name *
            <input style={{ ...inputStyle, borderColor: sangatValue.user_lastName === "" ? 'red' : "" }} value={sangatValue.user_lastName} onChange={e => setSangatLastName(e)} />
            Phone Number*
            <input type="tel" max="10" placeholder="no dashes"required style={{ ...inputStyle, borderColor: sangatValue.user_phoneNumber === "" ? 'red' : "" }} value={sangatValue.user_phoneNumber} onChange={e => setSangatPhoneNUmber(e)} />
            <small>Format: 12345678</small>
            {showValidationMessages && <div style={{borderColor: "red"}}>Invalid phone number</div>}
            </div>
            : data != null &&
            <Container fluid>
              <Row style={{ fontWeight: 'bold' }}>
                <Col>Fist Name</Col>
                <Col>Last Name</Col>
                <Col>City</Col>
              </Row>
              <Row>
                <Col>{data.user_firstName}</Col>
                <Col>{data.user_lastName}</Col>
                <Col>{data.user_city}</Col>
              </Row>
              <Row style={{ marginTop: '20px', fontWeight: 'bold' }}>
                <Col>Arriving Date</Col>
                <Col>Flight Info</Col>
                {data.user_ride_from_airport === 'YES' && <Col>Assigned Taxi</Col>}
              </Row>
              <Row>
                <Col>{data.user_arrivingFlightDate}</Col>
                <Col>{data.user_arrivingFlightName} {data.user_arrivingFlightNumber}</Col>
                {data.user_ride_from_airport === 'YES' && <Col>Please check back 24 hours prior to landing</Col>}
              </Row>
            </Container>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {userInput &&
            <Button variant="primary" onClick={addNewHost} disable={showValidationMessages}>
              Save Changes
          </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );

}


export default UserTravelInfoLookUp;