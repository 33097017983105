import React from 'react';

import Loader from './helper/loader';

class OfflineLogin extends React.Component {
    constructor(props){
        super(props);

    }
        

    openPendingForm = (e) =>{
        e.preventDefault();
        this.props.history.push({
            pathname: '/PendingUser',
         });
    }

    render(){
        return(
            <div style={{padding: "10px"}}>
  

                <div style={{marginTop: '15px'}}>
                <button class="btn btn-primary" onClick={e=>{this.openPendingForm(e)}}>Submit Flight Info</button>
                </div>
            </div>
        )
    }

}

export default OfflineLogin