import React from 'react';


import GenericInputForm from './helper/genericInputForm';


const AddEditUser = (props) => {
  const { user, handleCloseCallback, currentUser, onSaveSuccessCallBack, action, view } = props;


return (
  <GenericInputForm text={action} handleCloseCallback={handleCloseCallback} user={user} currentUser={currentUser} onSaveSuccessCallBack={onSaveSuccessCallBack} action={action} view={view}/>
    )
}    



export default AddEditUser;