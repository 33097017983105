import React, { useState } from 'react';
import { Modal, Button, } from 'react-bootstrap';
import Loader from '../helper/loader';
import { HerokuURL } from '../../constants';
import { Alert, Snackbar } from '@mui/material';




const ConfirmationModal = (props) => {



    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("WARNING! THIS ACTION IS IRREVERSABLE");
    const [snack, setSnack] = useState({ message: "", duration: 0, open: false, severity: "success" })




    const handleClose = () => {
        props.handleClose();
    }

    const closeSnack = () => {
        setSnack({ ...snack, open: false });
      }

    const onSubmit = async () => {
        setLoading(true);
        await fetch(`${HerokuURL}api/deleteUser`, {
            method: 'DELETE',
            headers: {
                "x-access-token": localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                props.user
            ),
        });

        setSnack({ open: true, duration: 6000, message: "User Deleted" });
        setLoading(false);
        handleClose();
    }


    return (
        <>
            <Modal size="lg" show={show} onHide={handleClose}>
                {loading && <Loader />}
                <Modal.Header closeButton>
                    <Modal.Title> {message} </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#f2f2f2' }}>
                    <h1> Are you sure you want to delete this user? </h1>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
          </Button>

                    <Button variant="danger" onClick={onSubmit}>
                        DELETE
          </Button>
                    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={snack.open} autoHideDuration={snack.duration} onClose={closeSnack}>
                        <Alert onClose={closeSnack} variant="filled" severity={snack.severity} sx={{ width: '100%' }}>
                            {snack.message}
                        </Alert>
                    </Snackbar>

                </Modal.Footer>
            </Modal>
        </>
    );

}


export default ConfirmationModal;